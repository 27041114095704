//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import firebase from 'firebase'
import 'firebase/firestore';
import { mapActions, mapGetters } from 'vuex';
import { i18n } from "@/i18n";

export default {
  data() {
    return {
      language:'',
      cityLabel:this.i18n('drugDeal.city.selectYourCity'),
      fileName:'',
      loading:true,
      cities:[],
      regions:[],
      regionDialog:false,
      regionEn: '',
      regionAr: '',
      regionCity: "",
      invalidRegion:true,
      columns: [       
        { name: 'ID', align: 'center', label: 'ID', field: 'id',},
        { name: 'Region', align: 'center', label: 'Region', field: 'name',},
        { name: 'City', align: 'center', label: 'City', field: 'city',},
      ],
      pagination: {
            sortBy: 'desc',
            descending: false,
            page: 1,
            rowsPerPage: this.$q.screen.width == 1024 ? 14 : 7
      },
    }
  },
  components: {
  
        // [Cities.name]: Cities,
        // [Regions.name]: Regions
    },
computed: {
    pagesNumber () {
      return Math.ceil(this.regions.length / this.pagination.rowsPerPage)
    },
    ...mapGetters({
      saveLoading:'citiesRegions/form/saveLoading'
    }),
    disable(){
      if(!this.saveLoading && this.regionEn && this.regionAr && this.regionCity){
        return false
      }
      return true
    }
  },
  methods: {
    i18n(key, args) {
      return i18n(key, args);
    },
    ...mapActions({
      doCreate: 'citiesRegions/form/doCreate'
    }),
    doSubmit(){
  
      this.regions.forEach((region)=>{
        this.doCreate(region)
      })
      
    },
    onItemClick (city) {
      if(city) 
      {
        this.invalidRegion = false
        this.cityLabel = city.name.en
      }
      this.regionCity = {
          en: city.name.en,
          ar: city.name.ar
      }
      
    },
    async addRegion(){
      if(this.regionCity === "") this.invalidRegion = true
      else{
        this.invalidRegion = false
     
        const region = {}
        region['name'] = this.regionCity
        const regionName = 
          {
              en : this.regionEn,
              ar : this.regionAr
          }
        region['regions'] = {
          name: regionName
        }
        await this.doCreate(region)
    }
    this.regionDialog = false
    }
  },
  mounted(){
    this.language = localStorage.getItem('language')
  },
  async created(){
    await firebase.firestore().collection('city').onSnapshot({ includeMetadataChanges: true },async () => {
      this.cities = []
      this.regions = []
      const cities = await firebase.firestore().collection("city").get({source: 'cache'})
      cities.forEach(async (doc) => {
        let city = doc.data()
        city['id'] = doc.id
        city['regions'] = []
        let regions = await firebase.firestore().collection("city").doc(doc.id).collection("regions").get()
        regions.forEach(async (region) =>{
          let regionInCity = region.data()
          city['regions'].push(regionInCity)
          regionInCity['city'] = city.name
          this.regions.push(regionInCity)

        })

        this.cities.push(city)
        
      })
   
      
      this.loading = false
   })
  }
};
